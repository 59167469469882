import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { CategoryPage, CategoryPageProps } from '../../components/shop/products/CategoryPage'
import { withIsDispensaryPageContext } from '../../hoc/withIsDispensaryPageContext'

type CategoryPageTemplateProps = CategoryPageProps

export const query = graphql`
  query CategoryPageTemplate($id: String!) {
    magentoCategory(id: { eq: $id }) {
      breadcrumbs {
        category_name
        category_url_key
        category_url_path
      }
      name
      url_path
    }
  }
`

const CategoryPageTemplate: FC<CategoryPageTemplateProps> = ({ data, pageContext }) => {
  return <CategoryPage data={data} pageContext={pageContext} />
}

export default withIsDispensaryPageContext(CategoryPageTemplate)
